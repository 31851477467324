import {Redirect, Route} from 'react-router';
import * as React from 'react';
import {useRouter} from '../hooks/use-router';
import {useAuth} from '../hooks/use-auth';

/**
 * <ProtectedBusinessSpecificRoute> checks two things
 * 1. authentication: if not, redirect to unauthorized
 * 2. business selected: if not, redirect to business-selection
 */
export default function ProtectedBusinessSpecificRoute(props: {
    isAuthorized: boolean;
    component: React.ComponentType<any>;
    path: string | string[];
    exact: boolean;
}) {
    const auth = useAuth();
    const router = useRouter();

    let componentToBeUsed = props.component;
    if (!props.isAuthorized) {
        /* eslint-disable-next-line react/display-name */
        componentToBeUsed = () => <Redirect to={{pathname: '/unauthorized'}} />;
    } else if (!auth.authInformation?.current && router.pathname !== '/business-selection') {
        /* eslint-disable-next-line react/display-name */
        componentToBeUsed = () => <Redirect to={{pathname: '/business-selection'}} />;
    }

    return <Route exact={props.exact} path={props.path} component={componentToBeUsed} />;
}
