import React from 'react';
import {Orchestrator} from '@amzn/f3-excelsior-orchestrator-api';
import {Box, Container, Header, SpaceBetween, Button} from '@amzn/awsui-components-react';
import {ViewScheduleEvent} from './view-schedule-event';
import {AddScheduleEvent} from './add-schedule-event';

interface ScheduleContainerProps {
    headerContent: string;

    schedule?: Orchestrator.Types.DailySchedule;

    // Display an editable version of Schedule Container
    editable: boolean;

    // when editable is true, the following callback must be pass in
    onRemove?: (e: Orchestrator.Types.ScheduleEvent) => void;
    validate?: (e: Orchestrator.Types.ScheduleEvent) => boolean;
    onAdd?: (e: Orchestrator.Types.ScheduleEvent) => void;
}

export function ScheduleContainer(props: ScheduleContainerProps) {
    const body = (props.schedule?.events || []).map((e: Orchestrator.Types.ScheduleEvent) => {
        const actions = props.editable ? (
            <Button data-testid="remove-event-button" onClick={() => props.onRemove!(e)}>
                Remove
            </Button>
        ) : (
            <React.Fragment />
        );

        return (
            <Box variant="div" key={e.time}>
                <Header variant="h3" actions={actions}>
                    {e.time}
                </Header>
                <ViewScheduleEvent event={e} />
            </Box>
        );
    });

    return (
        <Container header={<Header variant="h2">{props.headerContent}</Header>}>
            <SpaceBetween size="m" direction="vertical">
                {body}
                {props.editable && <AddScheduleEvent validate={props.validate!} onAdd={props.onAdd!} />}
            </SpaceBetween>
        </Container>
    );
}
