import React from 'react';
import {Header} from '@amzn/awsui-components-react';

export default function Unauthorized() {
    return (
        <div className="main-header">
            <Header variant="h2">Authorization required!</Header>
            <p>Access is not allowed.</p>
        </div>
    );
}
