import React from 'react';

interface TimestampProps {
    datetime: Date;
    locales?: string;
    timeZone?: string;
    hour12?: boolean;
}

export default function Timestamp(props: TimestampProps): JSX.Element {
    const date = new Intl.DateTimeFormat(props.locales, {
        timeZone: props.timeZone,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }).format(props.datetime);

    const time = new Intl.DateTimeFormat(props.locales, {
        timeZone: props.timeZone,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: props.hour12,
    }).format(props.datetime);

    return <React.Fragment>{`${date} ${time}`}</React.Fragment>;
}
