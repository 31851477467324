import React, {useEffect, useState} from 'react';
import {DatePicker, Grid, TimeInput} from '@amzn/awsui-components-react';

interface DateTimePickerProps {
    prefillDate: Date;
    onChange: (datetime: Date) => void;
}

export function DateTimePicker(props: DateTimePickerProps) {
    const timeFromPrefillDate = () => {
        const date = props.prefillDate;
        let hour = '' + date.getHours();
        let minute = '' + date.getMinutes();
        let second = '' + date.getSeconds();

        if (hour.length < 2) {
            hour = '0' + hour;
        }
        if (minute.length < 2) {
            minute = '0' + minute;
        }
        if (second.length < 2) {
            second = '0' + second;
        }

        return [hour, minute, second].join(':');
    };
    const dateFromPrefillDate = () => {
        // Calculate the format of the prefill date
        const date = props.prefillDate;
        const year = date.getFullYear();
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    };

    const [time, setTime] = useState(timeFromPrefillDate);
    const [date, setDate] = useState(dateFromPrefillDate);

    // refresh every time the props changes
    useEffect(() => {
        setTime(timeFromPrefillDate());
        setDate(dateFromPrefillDate());
    }, [props.prefillDate]);

    return (
        <Grid gridDefinition={[{colspan: 5}, {colspan: 2}]}>
            <DatePicker
                nextMonthAriaLabel="Next month"
                previousMonthAriaLabel="Previous month"
                todayAriaLabel="Today"
                data-testid="date-input"
                onChange={({detail}) => {
                    setDate(detail.value);
                    props.onChange(new Date(`${detail.value}T${time}`));
                }}
                value={date}
                placeholder="YYYY/MM/DD"
            />
            <TimeInput
                data-testid="time-input"
                onChange={({detail}) => {
                    setTime(detail.value);
                    props.onChange(new Date(`${date}T${detail.value}`));
                }}
                value={time}
                format="hh:mm:ss"
                placeholder="hh:mm:ss"
                use24Hour={true}
            />
        </Grid>
    );
}
