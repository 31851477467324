import React, {useState} from 'react';
import {Button, ButtonProps} from '@amzn/awsui-components-react';
import {ConfirmationModal} from '../bulk-override/confirmation-modal';

export interface ButtonWithConfirmationProps {
    label: string;
    variant: ButtonProps.Variant;
    disabled: boolean;
    promptHeader: string;
    prompt: string;
    confirmPhrase: string;
    additionalInfo?: string;
    onConfirm: () => void;
    showCancel?: boolean;
    showInput?: boolean;
}

export function ButtonWithConfirmation(props: ButtonWithConfirmationProps) {
    const defaultProps = {
        showCancel: props.showCancel ?? true,
        showInput: props.showInput ?? true,
    };
    props = {...props, ...defaultProps};
    const [visible, setVisible] = useState(false);

    return (
        <React.Fragment>
            <Button data-testid="button" disabled={props.disabled} variant={props.variant} onClick={() => setVisible(true)}>
                {props.label}
            </Button>
            <ConfirmationModal
                visible={visible}
                promptHeader={props.promptHeader}
                prompt={props.prompt}
                confirmPhrase={props.confirmPhrase}
                additionalInfo={props.additionalInfo}
                onCancel={() => {
                    setVisible(false);
                }}
                onConfirm={() => {
                    setVisible(false);
                    props.onConfirm();
                }}
                showCancel={props.showCancel}
                showInput={props.showInput}
            />
        </React.Fragment>
    );
}
