import React, {useState} from 'react';
import {Box, ColumnLayout, Header, Input, SpaceBetween, Tiles, TimeInput, Toggle, Button} from '@amzn/awsui-components-react';
import {ValueWithLabel} from '../common/value-with-label';
import {Orchestrator} from '@amzn/f3-excelsior-orchestrator-api';

interface AddScheduleEventProps {
    validate: (e: Orchestrator.Types.ScheduleEvent) => boolean;
    onAdd: (e: Orchestrator.Types.ScheduleEvent) => void;
}

function emptyToUndefined(v: string) {
    return v === '' ? undefined : v;
}

export function AddScheduleEvent(props: AddScheduleEventProps) {
    const [addEvent, setAddEvent] = useState(true);
    const [time, setTime] = useState('00:00:00');
    const [haveSla, setHaveSla] = useState(false);
    const [eventType, setEventType] = useState('generator');
    const [overrideBusinessId, setOverrideBusinessId] = useState('');
    const [overrideCountry, setOverrideCountry] = useState('');
    const [overrideFlow, setOverrideFlow] = useState('');
    const [forecastType, setForecastType] = useState('SOP');
    const [ingestionData, setIngestionData] = useState('constraints');
    const [publishMethod, setPublishMethod] = useState('cradle');
    const [generateStartTimeCalculation, setGenerateStartTimeCalculation] = useState('at_start_of_week_sunday_zero_hour');
    const [hour, setHour] = useState('0');
    const [minute, setMinute] = useState('0');
    const [second, setSecond] = useState('0');
    const [modelGroupName, setModelGroupName] = useState('');
    const [modelApiVersion, setModelApiVersion] = useState('');

    function extractScheduleEvent(): {event: Orchestrator.Types.ScheduleEvent; valid: boolean} {
        const event: Orchestrator.Types.ScheduleEvent = {
            time,
        };

        const hourInt = Number(hour);
        const minuteInt = Number(minute);
        const secondInt = Number(second);
        if (haveSla) {
            if (Number.isNaN(hourInt) || Number.isNaN(minuteInt) || Number.isNaN(secondInt)) {
                return {event, valid: false};
            }
        }

        const sla =
            haveSla && (hourInt > 0 || minuteInt > 0 || secondInt > 0)
                ? {
                      hour: hourInt > 0 ? hourInt : undefined,
                      minute: minuteInt > 0 ? minuteInt : undefined,
                      second: secondInt > 0 ? secondInt : undefined,
                  }
                : undefined;

        if (sla) {
            event.sla = sla;
        }

        if (eventType === 'generator') {
            event.generator = {
                startTimeCalculation: generateStartTimeCalculation,
                forecastType,
                overrideBusinessId: emptyToUndefined(overrideBusinessId),
                overrideCountry: emptyToUndefined(overrideCountry),
                overrideFlow: emptyToUndefined(overrideFlow),
                modelGroupName: emptyToUndefined(modelGroupName),
                modelApiVersion: emptyToUndefined(modelApiVersion),
            };
        }

        if (eventType === 'promote') {
            event.promote = {};
        }

        if (eventType === 'computeBias') {
            event.computeBias = {};
        }

        if (eventType === 'publish') {
            event.publish = {
                method: publishMethod,
                forecastType,
                overrideBusinessId: emptyToUndefined(overrideBusinessId),
                overrideCountry: emptyToUndefined(overrideCountry),
                overrideFlow: emptyToUndefined(overrideFlow),
            };
        }

        if (eventType === 'ingestion') {
            event.ingestion = {
                data: ingestionData,
            };
        }

        return {
            event,
            valid: true,
        };
    }

    if (addEvent) {
        return (
            <Box float="right" variant="div">
                <Button data-testid="add-new-event-button" onClick={() => setAddEvent(false)}>
                    Add new event
                </Button>
            </Box>
        );
    } else {
        const slaSettings = (
            <SpaceBetween size="xs" direction="horizontal">
                <Input
                    data-testid="hour-input"
                    invalid={Number.isNaN(Number(hour))}
                    onChange={({detail}) => setHour(detail.value)}
                    value={hour}
                    inputMode="numeric"
                />
                <Box variant="p">hour(s)</Box>
                <Input
                    data-testid="minute-input"
                    invalid={Number.isNaN(Number(minute))}
                    onChange={({detail}) => setMinute(detail.value)}
                    value={minute}
                    inputMode="numeric"
                />
                <Box variant="p">minute(s)</Box>
                <Input
                    data-testid="second-input"
                    invalid={Number.isNaN(Number(second))}
                    onChange={({detail}) => setSecond(detail.value)}
                    value={second}
                    inputMode="numeric"
                />
                <Box variant="p">second(s)</Box>
            </SpaceBetween>
        );

        const genericSettings = (
            <SpaceBetween size="l" direction="vertical">
                <ValueWithLabel label="Schedule Time (24 hours format)">
                    <TimeInput
                        data-testid="schedule-time-input"
                        onChange={({detail}) => setTime(detail.value)}
                        value={time}
                        format="hh:mm:ss"
                        placeholder="hh:mm:ss"
                        use24Hour={true}
                    />
                </ValueWithLabel>
                <ValueWithLabel
                    label={
                        <SpaceBetween size="xs" direction="horizontal">
                            <Box variant="p">Sla</Box>
                            <Toggle
                                data-testid="sla-toggle"
                                onChange={({detail}) => setHaveSla(detail.checked)}
                                checked={haveSla}
                            />
                        </SpaceBetween>
                    }
                >
                    {haveSla && slaSettings}
                </ValueWithLabel>
            </SpaceBetween>
        );

        const forecastTypeSettings = (
            <ValueWithLabel label="Forecast Type">
                <Tiles
                    data-testid="forecast-type-input"
                    onChange={({detail}) => setForecastType(detail.value)}
                    value={forecastType}
                    items={[
                        {label: 'SOP', value: 'SOP'},
                        {label: 'ASIN', value: 'ASIN'},
                    ]}
                />
            </ValueWithLabel>
        );

        const overrideBusinessIdSettings = (
            <ValueWithLabel label="Override Business ID (optional)">
                <Input
                    data-testid="override-business-id-input"
                    onChange={({detail}) => setOverrideBusinessId(detail.value)}
                    value={overrideBusinessId}
                />
            </ValueWithLabel>
        );

        const overrideCountrySettings = (
            <ValueWithLabel label="Override Country (optional)">
                <Input
                    data-testid="override-country-input"
                    onChange={({detail}) => setOverrideCountry(detail.value)}
                    value={overrideCountry}
                />
            </ValueWithLabel>
        );

        const overrideFlowSettings = (
            <ValueWithLabel label="Override Flow (optional)">
                <Input
                    data-testid="override-flow-input"
                    onChange={({detail}) => setOverrideFlow(detail.value)}
                    value={overrideFlow}
                />
            </ValueWithLabel>
        );

        let eventTypeSettings = <React.Fragment />;
        if (eventType === 'generator') {
            eventTypeSettings = (
                <React.Fragment>
                    <ValueWithLabel label="Calculation Start Time">
                        <Tiles
                            data-testid="generator-start-time-calculation-input"
                            onChange={({detail}) => setGenerateStartTimeCalculation(detail.value)}
                            value={generateStartTimeCalculation}
                            items={[
                                {label: 'At Start Of Week Sunday Zero Hour', value: 'at_start_of_week_sunday_zero_hour'},
                                {label: 'At Start Of Next Week Sunday Zero Hour', value: 'at_start_of_next_week_sunday_zero_hour'},
                            ]}
                        />
                    </ValueWithLabel>
                    {forecastTypeSettings}
                    {overrideBusinessIdSettings}
                    {overrideCountrySettings}
                    {overrideFlowSettings}
                    <ValueWithLabel label="Model Group Name (optional)">
                        <Input
                            data-testid="model-group-name-input"
                            onChange={({detail}) => setModelGroupName(detail.value)}
                            value={modelGroupName}
                        />
                    </ValueWithLabel>
                    <ValueWithLabel label="Model Api Version (optional)">
                        <Input
                            data-testid="model-api-version-input"
                            onChange={({detail}) => setModelApiVersion(detail.value)}
                            value={modelApiVersion}
                        />
                    </ValueWithLabel>
                </React.Fragment>
            );
        }

        if (eventType === 'publish') {
            eventTypeSettings = (
                <React.Fragment>
                    <ValueWithLabel label="Method">
                        <Tiles
                            data-testid="publish-method-input"
                            onChange={({detail}) => setPublishMethod(detail.value)}
                            value={publishMethod}
                            items={[
                                {label: 'Legacy', value: 'legacy'},
                                {label: 'Cradle', value: 'cradle'},
                            ]}
                        />
                    </ValueWithLabel>
                    {forecastTypeSettings}
                    {overrideBusinessIdSettings}
                    {overrideCountrySettings}
                    {overrideFlowSettings}
                </React.Fragment>
            );
        }

        if (eventType === 'ingestion') {
            eventTypeSettings = (
                <React.Fragment>
                    <ValueWithLabel label="Data">
                        <Tiles
                            data-testid="ingestion-data-input"
                            onChange={({detail}) => setIngestionData(detail.value)}
                            value={ingestionData}
                            items={[
                                {label: 'Constraints', value: 'constraints'},
                                {label: 'Actuals', value: 'actuals'},
                            ]}
                        />
                    </ValueWithLabel>
                </React.Fragment>
            );
        }

        const eventSettings = (
            <SpaceBetween size="l" direction="vertical">
                <ValueWithLabel label="Event Type">
                    <Tiles
                        data-testid="event-type-input"
                        onChange={({detail}) => setEventType(detail.value)}
                        value={eventType}
                        items={[
                            {label: 'Generate', value: 'generator'},
                            {label: 'Publish', value: 'publish'},
                            {label: 'Promote', value: 'promote'},
                            {label: 'Compute Bias', value: 'computeBias'},
                            {label: 'Ingestion', value: 'ingestion'},
                        ]}
                    />
                </ValueWithLabel>
                {eventTypeSettings}
            </SpaceBetween>
        );

        const {event, valid} = extractScheduleEvent();
        const isValidEvent = valid && props.validate(event!);

        return (
            <Box variant="div">
                <Header variant="h3">Define the new event</Header>
                <SpaceBetween size="l" direction="vertical">
                    <ColumnLayout columns={2} variant="text-grid">
                        {eventSettings}
                        {genericSettings}
                    </ColumnLayout>
                    <Box float="right" variant="div">
                        <SpaceBetween size="xs" direction="horizontal">
                            <Button data-testid="cancel-button" onClick={() => setAddEvent(true)}>
                                Cancel
                            </Button>
                            <Button
                                data-testid="add-button"
                                disabled={!isValidEvent}
                                variant="primary"
                                onClick={() => {
                                    props.onAdd(event!);
                                    setAddEvent(true);
                                }}
                            >
                                Add
                            </Button>
                        </SpaceBetween>
                    </Box>
                </SpaceBetween>
            </Box>
        );
    }
}
