import React from 'react';
import {Box} from '@amzn/awsui-components-react';

export function ValueWithLabel(props: {label: React.ReactNode; children: React.ReactNode}) {
    return (
        <Box variant="div">
            <Box variant="awsui-key-label">{props.label}</Box>
            <Box variant="div">{props.children}</Box>
        </Box>
    );
}
