import React from 'react';
import TopNavigationBar from './top-navigation-bar';
import {Flashbar, FlashbarProps} from '@amzn/awsui-components-react';
import {useArrayState} from '../hooks/use-array-state';
import {useRouter} from '../hooks/use-router';

export interface Notification {
    header?: React.ReactNode;
    content?: React.ReactNode;
    loading?: boolean;
    type?: FlashbarProps.Type;
    action?: React.ReactNode;
}

function isSame(element: FlashbarProps.MessageDefinition, removeValue: FlashbarProps.MessageDefinition) {
    return (
        element.header === removeValue.header &&
        element.content === removeValue.content &&
        element.loading === removeValue.loading &&
        element.type === removeValue.type &&
        element.action === removeValue.action
    );
}

interface PageLocationState {
    notification: Notification;
}

export default function PageLayout(props: {component: React.ComponentType<any>}) {
    const {location} = useRouter();
    const {
        array: items,
        add,
        remove,
    } = useArrayState<FlashbarProps.MessageDefinition>(() => {
        const pageState = location.state as PageLocationState | undefined;
        if (pageState?.notification) {
            const initialMessage: FlashbarProps.MessageDefinition = {
                ...pageState.notification,
                dismissible: true,
                onDismiss: () => remove(initialMessage),
            };

            return [initialMessage];
        }
        return [];
    }, isSame);

    function pushNotification(notification: Notification) {
        const originalMessage: FlashbarProps.MessageDefinition = {
            ...notification,
            dismissible: true,
            onDismiss: () => remove(originalMessage),
        };
        add(originalMessage);
    }

    return (
        <React.Fragment>
            <TopNavigationBar />
            <Flashbar items={items} data-testid="notifications" />
            <props.component pushNotification={pushNotification} />
        </React.Fragment>
    );
}

/**
 * return a HOC (higher-level order component) wrapping PageLayout.
 * This is used to integrate with "component" props in <Route/>
 */
export function withPageLayout(component: React.ComponentType<any>): React.ComponentType<any> {
    /* eslint-disable-next-line react/display-name */
    return () => <PageLayout component={component} />;
}
