import React from 'react';
import PolarisButton, {ButtonProps as PolarisButtonProps} from '@amzn/awsui-components-react/polaris/button';
import {useHistory} from 'react-router-dom';

export function isSpecialKey(event: CustomEvent<PolarisButtonProps.ClickDetail>) {
    return (
        event.detail.button !== 0 || event.detail.ctrlKey || event.detail.altKey || event.detail.shiftKey || event.detail.metaKey
    );
}

/**
 * A wrapper around Polaris Button to integrate with React Router.
 * The user uses 'href' to set the path they want to go to.
 */
export default function RouterButton(props: PolarisButtonProps & {children: React.ReactNode}) {
    const history = useHistory();
    function onClickHandler(event: CustomEvent<PolarisButtonProps.ClickDetail>) {
        if (props.href && !isSpecialKey(event) && props.target === undefined) {
            event.preventDefault();
            history.push(props.href);
        }
        if (props.onClick) {
            props.onClick(event);
        }
    }
    return <PolarisButton {...props} href={undefined} onClick={onClickHandler} />;
}
