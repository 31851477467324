import React, {useCallback} from 'react';
import {DropzoneOptions, FileRejection, useDropzone} from 'react-dropzone';
import axios from 'axios';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {F3ExcelsiorForecastStoreLambda} from '@amzn/f3-excelsior-forecast-store-lambda';
import {AlertProps} from '@amzn/awsui-components-react';

export interface TemplateDropzoneProps {
    templateType: string;
    useBulkOverrideV2?: boolean;
    // max number of polling status attempts
    maxPollAttempt?: number;
    delayBetweenAttemptInMs?: number;
    onChange(message: string, type: AlertProps.Type, action?: React.ReactNode): void;
}

export function TemplateDropzone(props: TemplateDropzoneProps) {
    const {onDrop} = useTemplateDropzone(props);
    const {getRootProps, getInputProps} = useDropzone({
        onDrop: onDrop,
        // Maximum accepted number of files
        maxFiles: 1,
        accept: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    } as DropzoneOptions);

    return (
        <div className={'dropzone-div'} {...getRootProps()}>
            <input className={'dropzone'} {...getInputProps()} />
            <p>Drag and drop some files here, or click to select files.</p>
        </div>
    );
}

// Visible for testing
export function useTemplateDropzone(props: TemplateDropzoneProps, mockClient?: F3ExcelsiorForecastStoreLambda) {
    const auth = useAuth();

    const forecastStoreViewClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreView);
    const forecastStoreEditClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreEdit);

    const forecastStoreViewClient = mockClient || new F3ExcelsiorForecastStoreLambda(forecastStoreViewClientConfiguration);
    const forecastStoreEditClient = mockClient || new F3ExcelsiorForecastStoreLambda(forecastStoreEditClientConfiguration);

    const uploadFileToS3 = useCallback(
        (url: string, file: File) => {
            return new Promise<boolean>((resolve) => {
                const fileUploadResult = axios.put(url, file, {
                    headers: {
                        'Content-Type': file.type,
                        'Access-Control-Allow-Origin': '*',
                        'x-amz-server-side-encryption': 'aws:kms',
                    },
                });

                fileUploadResult
                    .then(() => {
                        resolve(true);
                    })
                    .catch(() => {
                        props.onChange('File upload to S3 failed. Please try again.', 'error');
                        resolve(false);
                    });
            });
        },
        [props]
    );

    //Method for what to do on drop or select!
    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            if (fileRejections.length > 0) {
                props.onChange('The file was rejected due to format. Files need to be in format: .xlsx', 'error');
                return;
            }

            acceptedFiles.forEach(async (file: File) => {
                props.onChange('Your file is currently being processed. Please wait for the outcome.', 'info');

                try {
                    const getLatestForecastIdsMetadataResponse = await forecastStoreViewClient
                        .getLatestForecastIdsMetadata({
                            businessId: auth.authInformation!.current!.businessId,
                            country: auth.authInformation!.current!.country,
                            flow: auth.authInformation!.current!.flow,
                        })
                        .promise();

                    if (getLatestForecastIdsMetadataResponse.latestForecastIdsMetadata.length != 1) {
                        props.onChange('There is no forecast to override', 'error');
                        return;
                    }

                    const forecastRecord = getLatestForecastIdsMetadataResponse.latestForecastIdsMetadata[0];
                    const forecastId = forecastRecord.forecastId as string;
                    let uploadMetadata: {uploadId: string; uploadUrl: string};
                    if (props.useBulkOverrideV2) {
                        uploadMetadata = await forecastStoreEditClient
                            .uploadBulkOverrideTemplate({
                                businessId: auth.authInformation!.current!.businessId,
                                country: auth.authInformation!.current!.country,
                                flow: auth.authInformation!.current!.flow,
                                templateId: props.templateType,
                                uploadedFileName: file.name,
                                forecastId,
                            })
                            .promise();
                    } else {
                        uploadMetadata = await forecastStoreEditClient
                            .prepareForecastAssetUpload({
                                businessId: auth.authInformation!.current!.businessId,
                                country: auth.authInformation!.current!.country,
                                flow: auth.authInformation!.current!.flow,
                                templateType: props.templateType,
                                fileName: file.name,
                            })
                            .promise();
                    }

                    if (!(await uploadFileToS3(uploadMetadata.uploadUrl, file))) {
                        return;
                    }

                    let pollingAttempt = 0;

                    const delay = props.delayBetweenAttemptInMs || 1000;
                    const maxPollAttempt = props.maxPollAttempt || 900;

                    const interval = setInterval(async () => {
                        if (pollingAttempt >= maxPollAttempt) {
                            props.onChange('Upload has timed out. Please try again.', 'error');
                            clearInterval(interval);
                            return;
                        }

                        try {
                            const getForecastAssetUploadStatusResponse = await forecastStoreViewClient
                                .getForecastAssetUploadStatus({
                                    uploadId: uploadMetadata.uploadId,
                                })
                                .promise();

                            if (getForecastAssetUploadStatusResponse.status === 'succeeded') {
                                props.onChange('Your file has been successfully uploaded.', 'success');
                                clearInterval(interval);
                                return;
                            } else if (getForecastAssetUploadStatusResponse.status === 'failed') {
                                let errorMessage = '';
                                let action: React.ReactNode;

                                if (getForecastAssetUploadStatusResponse.fileValidationErrors) {
                                    let fileErrors = '';
                                    if (getForecastAssetUploadStatusResponse.isExcessiveValidationErrors) {
                                        action = 'There are more errors than being shown.';
                                    }

                                    for (let i = 0; i < getForecastAssetUploadStatusResponse.fileValidationErrors.length; i++) {
                                        // Hack to ensure we don't append extra period at the end of error message
                                        const errorMessage = getForecastAssetUploadStatusResponse.fileValidationErrors[i] as string;
                                        const suffix = errorMessage.endsWith('.') ? '' : '.';
                                        // eslint-disable-next-line
                                        fileErrors += `\n\t\t${i + 1})\t${errorMessage}${suffix}`;
                                    }
                                    errorMessage = 'File ' + file.name + ' has file validation errors of: ' + fileErrors;
                                } else {
                                    errorMessage = 'File '.concat(
                                        file.name,
                                        ' could not upload due to a service error. Please try again.'
                                    );
                                }

                                props.onChange(errorMessage, 'error', action);
                                clearInterval(interval);
                                return;
                            }

                            pollingAttempt++;
                        } catch (error) {
                            clearInterval(interval);
                            props.onChange('There is a service error. Please contact someone from the support team.', 'error');
                        }
                    }, delay);
                } catch (error) {
                    props.onChange('There is a service error. Please contact someone from the support team.', 'error');
                }
            });
            return;
        },
        [auth, props]
    );

    return {onDrop};
}
