import React from 'react';
import Loader from 'react-loader-spinner';

const PortalLoader = () => (
    <div data-testid="loader" className="loader">
        <Loader type="Rings" color="#00BFFF" height={100} width={100} />
    </div>
);

export default PortalLoader;
