import {useCallback, useEffect, useState} from 'react';

/**
 * It's generally a good practice to indicate to users the status of any async request.
 * An example would be fetching data from an API and displaying a loading indicator
 * before rendering the results. Another example would be a form where you want to disable
 * the submit button when the submission is pending and then display either a success or
 * error message when it completes.
 *
 * Rather than litter your components with a bunch of useState calls to keep track of the state of an async function,
 * you can use our custom hook which takes an async function as an input and returns the value, error, and status
 * values we need to properly update our UI. Possible values for status prop are: "idle", "pending", "success",
 * "error". As you'll see in the code below, our hook allows both immediate execution and delayed execution using
 * the returned execute function.
 *
 * https://usehooks.com/useAsync/
 */
export type AsyncStatus = 'idle' | 'pending' | 'success' | 'error';

/**
 * @param asyncFunction need to be have a stable identity; probably need to wrap by useCallback()
 * @param immediate
 */
export function useAsync<R>(asyncFunction: () => Promise<R>, immediate = true) {
    const {execute, status, value, error} = useExecute<R, () => Promise<R>>(asyncFunction);
    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);
    return {execute, status, value, error};
}

/**
 * useExecute() enables async/Promise to be wrapped with React hook useState()
 * Therefore, the user does not repeat to have async/Promise code.
 */
export function useExecute<R, T extends (...args: any[]) => Promise<R>>(asyncFunction: T) {
    const [status, setStatus] = useState('idle' as AsyncStatus);
    const [value, setValue] = useState(undefined as R | undefined);
    const [error, setError] = useState();
    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback(
        <T>(async (...args: any[]) => {
            setStatus('pending');
            setValue(undefined);
            setError(undefined);

            try {
                const response = await asyncFunction(...args);
                setValue(response as R);
                setStatus('success');
                return response;
            } catch (error) {
                setError(error);
                setStatus('error');
            }
        }),
        [asyncFunction]
    );

    return {execute, status, value, error};
}
