import {F3ExcelsiorForecastGeneratorLambda} from '@amzn/f3-excelsior-forecast-generator-lambda';
import {useAsync, useExecute} from './use-async';
import {wrapOnErrorListener} from '../common';
import {DependencyList, useCallback} from 'react';

/* eslint-disable react-hooks/exhaustive-deps */
export function useGetForecastValidationErrors(
    clientConfiguration: F3ExcelsiorForecastGeneratorLambda.Types.ClientConfiguration,
    request: F3ExcelsiorForecastGeneratorLambda.Types.GetForecastValidationErrorsRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastGeneratorLambda(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.getForecastValidationErrors(request).promise(), onErrorListener), deps),
        immediate
    );
}

export function useGenerateForecast(
    clientConfiguration: F3ExcelsiorForecastGeneratorLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastGeneratorLambda(clientConfiguration)
) {
    return useExecute<
        F3ExcelsiorForecastGeneratorLambda.Types.TriggerForecastResponse,
        (
            r: F3ExcelsiorForecastGeneratorLambda.Types.TriggerForecastRequest
        ) => Promise<F3ExcelsiorForecastGeneratorLambda.Types.TriggerForecastResponse>
    >(
        useCallback(
            (request: F3ExcelsiorForecastGeneratorLambda.Types.TriggerForecastRequest) =>
                wrapOnErrorListener(client.triggerForecast(request).promise(), onErrorListener),
            deps
        )
    );
}

/* eslint-enable react-hooks/exhaustive-deps */
