import React from 'react';
import {Button} from '@amzn/awsui-components-react';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {translateErrorToReactNode} from '../common';
import {useDownloadTemplateV1, useDownloadTemplateV2} from '../hooks/use-forecast-store-api';
import {Notification} from '../navigation/page-layout';

interface DownloadTemplateButtonProps {
    templateName: string;
    templateType: string;
    useBulkOverrideV2?: boolean;
    pushNotification: (notification: Notification) => void;
}

export function DownloadTemplateButton(props: DownloadTemplateButtonProps) {
    const auth = useAuth();

    const clientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreEdit);

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const {execute: executeDownloadTemplateV1} = useDownloadTemplateV1(
        clientConfiguration,
        {
            businessId: auth.authInformation!.current!.businessId,
            flow: auth.authInformation!.current!.flow,
            country: auth.authInformation!.current!.country,
            templateType: props.templateType,
        },
        createErrorListener('DownloadTemplateV1 failed'),
        [auth]
    );

    const {execute: executeDownloadTemplateV2} = useDownloadTemplateV2(
        clientConfiguration,
        {
            businessId: auth.authInformation!.current!.businessId,
            flow: auth.authInformation!.current!.flow,
            country: auth.authInformation!.current!.country,
            templateId: props.templateType,
        },
        createErrorListener('DownloadTemplateV2 failed'),
        [auth]
    );

    const handleDownloadClick = () => {
        if (props.useBulkOverrideV2) {
            executeDownloadTemplateV2();
        } else {
            executeDownloadTemplateV1();
        }
    };

    return (
        <Button data-testid="download-button" variant="normal" onClick={handleDownloadClick}>
            {props.templateName}
        </Button>
    );
}
