import Analytics from '@aws-amplify/analytics';

interface PortalEvent {
    eventName: string;
    eventData: any;
}

export function recordUserEvent(event: PortalEvent) {
    const date = new Date();
    const data = {
        ...event,
        eventDateTime: new Date(date.toUTCString()).toISOString(),
    };
    try {
        Analytics.record(
            {
                data,
                streamName: 'UxPortalWebAnalyticsStream', // TODO: probably also want this to come from settings.json, although the name is the same across all environments
            },
            'AWSKinesisFirehose'
        );
    } catch (e) {
        // don't re-throw error as we don't want the portal to crash when analytics logging fails
    }
}
