import React from 'react';
import '../../assets/styles/support-styles.scss';
import Resources from './resources';
import Help from './help';
import Faq from './faq';

export default function Support() {
    return (
        <div>
            <Resources />
            <Faq />
            <Help />
        </div>
    );
}
