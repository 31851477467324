import React, {useState} from 'react';
import {DashboardEvents} from '@amzn/f3-excelsior-orchestrator-api/clients/orchestrator';
import {Modal, Box, SpaceBetween, Button, ButtonProps} from '@amzn/awsui-components-react';
import EventStatisticsTable from './event-statistics-table';

interface ViewStatisticsModalProps {
    label: string;
    disabled: boolean;
    variant: ButtonProps.Variant;
    orchestratorEvents: DashboardEvents;
}

export default function ViewStatisticsButton(props: ViewStatisticsModalProps) {
    const [visible, setVisible] = useState(false);

    function onConfirm() {
        setVisible(false);
    }

    return (
        <>
            <Button
                data-testid="view-statistics-button"
                disabled={props.disabled}
                variant={props.variant}
                onClick={() => setVisible(true)}
            >
                {props.label}
            </Button>
            <Modal
                data-testid="view-statistics-modal"
                visible={visible}
                header="Event Statistics"
                size="large"
                onDismiss={onConfirm}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button data-testid="view-statistics-modal-ok-button" variant="primary" onClick={onConfirm}>
                                Ok
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <EventStatisticsTable events={props.orchestratorEvents}></EventStatisticsTable>
            </Modal>
        </>
    );
}
