import {useState} from 'react';

/**
 * useArrayState to manage the array entire as state
 * with mutators: add and remove support.
 */
export function useArrayState<S>(initialState: Array<S> | (() => Array<S>), isSame: (element: S, removeValue: S) => boolean) {
    const [array, setArray] = useState(initialState);

    function remove(value: S) {
        setArray((prev) => prev.filter((e) => !isSame(e, value)));
    }

    function add(value: S) {
        setArray((prev) => prev.concat([value]));
    }

    return {
        array,
        remove,
        add,
    };
}
