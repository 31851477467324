import React from 'react';
import {Header} from '@amzn/awsui-components-react';

type ErrorHandler = (error: Error, info: React.ErrorInfo) => void;
type ErrorHandlingComponent<Props> = (props: Props, error?: Error) => React.ReactNode;

type ErrorState = {error?: Error};

export interface ErrorPageProps {
    children: React.ReactNode;
}

/**
 * https://reactjs.org/docs/error-boundaries.html
 * Plus magic
 * https://gist.github.com/andywer/800f3f25ce3698e8f8b5f1e79fed5c9c
 */
export const FatalErrorPage = Catch(function InternalErrorBoundary(props: ErrorPageProps, error?: Error) {
    if (error) {
        return (
            <React.Fragment>
                <div className="main-header">
                    <Header variant="h2">An error has occured</Header>
                </div>
                <div className="main-content">
                    <p>{error.message}</p>
                    <p>{error.stack}</p>
                </div>
            </React.Fragment>
        );
    } else {
        return <React.Fragment>{props.children}</React.Fragment>;
    }
});

export function Catch<ErrorPageProps>(
    component: ErrorHandlingComponent<ErrorPageProps>,
    errorHandler?: ErrorHandler
): React.ComponentType<ErrorPageProps> {
    /* eslint-disable-next-line react/display-name */
    return class extends React.Component<ErrorPageProps, ErrorState> {
        state: ErrorState = {
            error: undefined,
        };

        static getDerivedStateFromError(error: Error) {
            return {error};
        }

        componentDidCatch(error: Error, info: React.ErrorInfo) {
            if (errorHandler) {
                errorHandler(error, info);
            }
        }

        render() {
            return component(this.props, this.state.error);
        }
    };
}
