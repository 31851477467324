import {useState} from 'react';

/**
 * Sync state to local storage so that it persists through
 * a page refresh. Usage is similar to useState except we
 * pass in a local storage key so that we can default to
 * that value on page load instead of the specified initial value.
 *
 * https://usehooks.com/useLocalStorage
 */
export const useLocalStorage = (key: string, initialValue?: string) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [value, setStoredValue] = useState(() => {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ?? initialValue;
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value?: string) => {
        setStoredValue(value);
        if (value) {
            // Save to local storage
            window.localStorage.setItem(key, value);
        } else {
            window.localStorage.removeItem(key);
        }
    };
    return {value, setValue};
};
