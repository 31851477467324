import React from 'react';
import {Header} from '@amzn/awsui-components-react';

export default function Resources() {
    return (
        <div className={'mainArea'}>
            <Header variant="h1">Resources</Header>
            <a
                href="https://w.amazon.com/bin/view/F3/F3_Grocery_Supply_Chain/Sales_And_Operations_Planning/Excelsior#HDeveloperEnvironmentSetup"
                className="awsui-util-font-size-3"
                target={'_blank'}
                rel={'noopener noreferrer'}
            >
                Team Wiki
            </a>
        </div>
    );
}
