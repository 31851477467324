import React from 'react';
import {Notification} from '../navigation/page-layout';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {Orchestrator} from '@amzn/f3-excelsior-orchestrator-api';
import {useDescribeSchedule} from '../hooks/use-orchestrator-api';
import {translateErrorToReactNode} from '../common';
import {Button, Container, Header, SpaceBetween, Spinner} from '@amzn/awsui-components-react';
import RouterButton from '../navigation/router-button';
import {ValueWithLabel} from '../common/value-with-label';
import {ScheduleContainer} from './schedule-container';

export function ViewSchedule(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();

    const clientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.OrchestratorView);

    const errorListener = (e: any) => {
        props.pushNotification({
            type: 'error',
            content: translateErrorToReactNode(e),
            header: 'DescribeSchedule failed',
        });
    };

    const request = {
        businessId: auth.authInformation!.current!.businessId,
        country: auth.authInformation!.current!.country,
        flow: auth.authInformation!.current!.flow,
    };
    const {
        execute: executeDescribeSchedule,
        status: executeDescribeScheduleStatus,
        value: describeScheduleResponse,
    } = useDescribeSchedule(clientConfiguration, request, true, errorListener, [auth]);

    const isLoading = executeDescribeScheduleStatus === 'idle' || executeDescribeScheduleStatus === 'pending';
    const isSuccessful = executeDescribeScheduleStatus === 'success';
    const businessDescription = `for businessId: ${request.businessId}, country: ${request.country}, flow: ${request.flow}`;

    const timeDescription = describeScheduleResponse?.updatedDateTime
        ? `Last updated time: ${describeScheduleResponse?.updatedDateTime.toLocaleString()}`
        : '';

    const isWeeklySchedule = describeScheduleResponse?.schedule?.weekly;
    const isDailySchedule = describeScheduleResponse?.schedule?.daily;

    let scheduleSettingContent = <React.Fragment />;
    if (isSuccessful) {
        const cadence = isWeeklySchedule ? 'Weekly' : isDailySchedule ? 'Daily' : 'Unknown';
        const timezone = describeScheduleResponse!.schedule.timezone!;
        scheduleSettingContent = (
            <SpaceBetween size="m" direction="vertical">
                <ValueWithLabel label="Cadence">{cadence}</ValueWithLabel>
                <ValueWithLabel label="Timezone">{timezone}</ValueWithLabel>
            </SpaceBetween>
        );
    } else if (isLoading) {
        scheduleSettingContent = <Spinner size={'large'} />;
    }

    let scheduleDescriptionContent: React.ReactNode = <React.Fragment />;
    if (isWeeklySchedule) {
        const weeklySchedule = describeScheduleResponse!.schedule.weekly!;
        const dailyScheduleByDayOfWeek = [
            {
                key: 'Sunday',
                schedule: weeklySchedule.sunday,
            },
            {
                key: 'Monday',
                schedule: weeklySchedule.monday,
            },
            {
                key: 'Tuesday',
                schedule: weeklySchedule.tuesday,
            },
            {
                key: 'Wednesday',
                schedule: weeklySchedule.wednesday,
            },
            {
                key: 'Thursday',
                schedule: weeklySchedule.thursday,
            },
            {
                key: 'Friday',
                schedule: weeklySchedule.friday,
            },
            {
                key: 'Saturday',
                schedule: weeklySchedule.saturday,
            },
        ];

        scheduleDescriptionContent = dailyScheduleByDayOfWeek
            .filter(
                (v: {key: string; schedule: Orchestrator.Types.DailySchedule | undefined}) => (v.schedule?.events?.length || 0) > 0
            )
            .map((v: {key: string; schedule: Orchestrator.Types.DailySchedule | undefined}) => (
                <ScheduleContainer key={v.key} headerContent={`${v.key} Schedule`} schedule={v.schedule} editable={false} />
            ));
    } else if (isDailySchedule) {
        const dailySchedule = describeScheduleResponse!.schedule.daily!;
        scheduleDescriptionContent = (
            <ScheduleContainer headerContent={'Daily Schedule'} schedule={dailySchedule} editable={false} />
        );
    }

    const scheduleDescription: React.ReactNode = isSuccessful ? scheduleDescriptionContent : <React.Fragment />;

    return (
        <React.Fragment>
            <div className="main-header">
                <Header
                    variant="h1"
                    description={`${businessDescription}. ${timeDescription}`}
                    actions={
                        <SpaceBetween size="xs" direction="horizontal">
                            <Button
                                data-testid="refresh-button"
                                iconName="refresh"
                                variant="normal"
                                disabled={isLoading}
                                onClick={() => executeDescribeSchedule()}
                            />
                            <RouterButton data-testid="edit-schedule-button" variant="primary" href={'/schedule/edit'}>
                                Edit
                            </RouterButton>
                        </SpaceBetween>
                    }
                >
                    Schedule
                </Header>
            </div>
            <div className="main-content">
                <SpaceBetween size="xxl" direction="vertical">
                    <Container header={<Header variant="h2">Schedule Settings</Header>}>{scheduleSettingContent}</Container>
                    {scheduleDescription}
                </SpaceBetween>
            </div>
        </React.Fragment>
    );
}
