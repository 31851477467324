import React, {useState} from 'react';
import {Notification} from '../navigation/page-layout';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {Orchestrator} from '@amzn/f3-excelsior-orchestrator-api';
import {translateErrorToReactNode} from '../common';
import {useDescribeSchedule, usePutSchedule} from '../hooks/use-orchestrator-api';
import {Autosuggest, Box, Container, Header, SpaceBetween, Tiles, Toggle, Button} from '@amzn/awsui-components-react';
import RouterButton from '../navigation/router-button';
import {ValueWithLabel} from '../common/value-with-label';
import {ScheduleContainer} from './schedule-container';
import {useRouter} from '../hooks/use-router';
import timezones from 'timezones-list';

const AUTO_SUGGEST_TIMEZONES = timezones.map((tz) => ({value: tz.tzCode}));

type DayOfWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';

export function EditSchedule(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();
    const router = useRouter();

    const [reset, setReset] = useState(true);
    const [timezone, setTimezone] = useState<string>('');
    const [cadence, setCadence] = useState<string>('weekly');
    const [weeklySchedule, setWeeklySchedule] = useState<Orchestrator.Types.WeeklySchedule>({});
    const [dailySchedule, setDailySchedule] = useState<Orchestrator.Types.DailySchedule>({});

    const orchestratorViewClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.OrchestratorView);
    const orchestratorEditClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.OrchestratorEdit);

    const errorListener = (e: any) => {
        props.pushNotification({
            type: 'error',
            content: translateErrorToReactNode(e),
            header: 'PutSchedule failed',
        });
    };

    const request = {
        businessId: auth.authInformation!.current!.businessId,
        country: auth.authInformation!.current!.country,
        flow: auth.authInformation!.current!.flow,
    };
    const {status: executeDescribeScheduleStatus, value: describeScheduleResponse} = useDescribeSchedule(
        orchestratorViewClientConfiguration,
        request,
        true,
        () => {},
        [auth]
    );

    const {execute: executePutSchedule} = usePutSchedule(orchestratorEditClientConfiguration, errorListener, [auth]);

    const businessDescription = `for businessId: ${request.businessId}, country: ${request.country}, flow: ${request.flow}`;

    const isPrefillable = executeDescribeScheduleStatus === 'success';
    const isValidRequest = checkValidRequest(reset, timezone, cadence, weeklySchedule, dailySchedule);

    function prefill() {
        if (describeScheduleResponse) {
            setTimezone(describeScheduleResponse.schedule.timezone);
            setDailySchedule(describeScheduleResponse.schedule.daily || {});
            setWeeklySchedule(describeScheduleResponse.schedule.weekly || {});
            setCadence(describeScheduleResponse.schedule.daily ? 'daily' : 'weekly');
        }
    }

    function makeDayOfWeekOnRemove(key: DayOfWeek) {
        return (e: Orchestrator.Types.ScheduleEvent) =>
            setWeeklySchedule(
                (prevState: Orchestrator.Types.WeeklySchedule) =>
                    ({
                        ...prevState,
                        [key]: {
                            events: (prevState[key]?.events || []).filter((existingEvent) => existingEvent !== e),
                        },
                    } as Orchestrator.Types.WeeklySchedule)
            );
    }

    function makeDayOfWeekOnAdd(key: DayOfWeek) {
        return (e: Orchestrator.Types.ScheduleEvent) =>
            setWeeklySchedule(
                (prevState: Orchestrator.Types.WeeklySchedule) =>
                    ({
                        ...prevState,
                        [key]: {
                            events: (prevState[key]?.events || []).concat([e]).sort((a, b) => compare(a.time, b.time)),
                        },
                    } as Orchestrator.Types.WeeklySchedule)
            );
    }

    function makeDayOfWeekValidate(key: DayOfWeek) {
        return (e: Orchestrator.Types.ScheduleEvent) =>
            (weeklySchedule[key]?.events || []).filter((existingEvent) => existingEvent.time === e.time).length === 0;
    }

    const dailyScheduleByDayOfWeek = [
        {
            key: 'Sunday',
            schedule: weeklySchedule.sunday,
            onRemove: makeDayOfWeekOnRemove('sunday'),
            onAdd: makeDayOfWeekOnAdd('sunday'),
            validate: makeDayOfWeekValidate('sunday'),
        },
        {
            key: 'Monday',
            schedule: weeklySchedule.monday,
            onRemove: makeDayOfWeekOnRemove('monday'),
            onAdd: makeDayOfWeekOnAdd('monday'),
            validate: makeDayOfWeekValidate('monday'),
        },
        {
            key: 'Tuesday',
            schedule: weeklySchedule.tuesday,
            onRemove: makeDayOfWeekOnRemove('tuesday'),
            onAdd: makeDayOfWeekOnAdd('tuesday'),
            validate: makeDayOfWeekValidate('tuesday'),
        },
        {
            key: 'Wednesday',
            schedule: weeklySchedule.wednesday,
            onRemove: makeDayOfWeekOnRemove('wednesday'),
            onAdd: makeDayOfWeekOnAdd('wednesday'),
            validate: makeDayOfWeekValidate('wednesday'),
        },
        {
            key: 'Thursday',
            schedule: weeklySchedule.thursday,
            onRemove: makeDayOfWeekOnRemove('thursday'),
            onAdd: makeDayOfWeekOnAdd('thursday'),
            validate: makeDayOfWeekValidate('thursday'),
        },
        {
            key: 'Friday',
            schedule: weeklySchedule.friday,
            onRemove: makeDayOfWeekOnRemove('friday'),
            onAdd: makeDayOfWeekOnAdd('friday'),
            validate: makeDayOfWeekValidate('friday'),
        },
        {
            key: 'Saturday',
            schedule: weeklySchedule.saturday,
            onRemove: makeDayOfWeekOnRemove('saturday'),
            onAdd: makeDayOfWeekOnAdd('saturday'),
            validate: makeDayOfWeekValidate('saturday'),
        },
    ];

    // no overlap with time
    const dailyScheduleValidate = (e: Orchestrator.Types.ScheduleEvent) =>
        (dailySchedule.events || []).filter((existingEvent) => existingEvent.time === e.time).length === 0;

    let configureSchedule: React.ReactNode = <React.Fragment />;
    if (cadence === 'weekly') {
        configureSchedule = dailyScheduleByDayOfWeek.map(
            (v: {
                key: string;
                schedule: Orchestrator.Types.DailySchedule | undefined;
                onRemove: (e: Orchestrator.Types.ScheduleEvent) => void;
                onAdd: (e: Orchestrator.Types.ScheduleEvent) => void;
                validate: (e: Orchestrator.Types.ScheduleEvent) => boolean;
            }) => (
                <ScheduleContainer
                    key={v.key}
                    headerContent={`${v.key} Schedule`}
                    schedule={v.schedule}
                    editable={true}
                    validate={v.validate}
                    onRemove={v.onRemove}
                    onAdd={v.onAdd}
                />
            )
        );
    } else if (cadence === 'daily') {
        configureSchedule = (
            <ScheduleContainer
                headerContent={'Daily Schedule'}
                schedule={dailySchedule}
                editable={true}
                onRemove={(e) =>
                    setDailySchedule((prevState) => {
                        return {
                            events: (prevState.events || []).filter((existingEvent) => existingEvent !== e),
                        };
                    })
                }
                validate={dailyScheduleValidate}
                onAdd={(e) => {
                    setDailySchedule((prevState) => {
                        return {
                            events: (prevState.events || []).concat([e]).sort((a, b) => compare(a.time, b.time)),
                        };
                    });
                }}
            />
        );
    }

    // Otherwise, display editable schedule
    return (
        <React.Fragment>
            <div className="main-header">
                <Header
                    variant="h1"
                    description={businessDescription}
                    actions={
                        <Button disabled={!isPrefillable} data-testid="prefill-schedule-button" variant="normal" onClick={prefill}>
                            Prefill Values with the Current Schedule
                        </Button>
                    }
                >
                    Edit Schedule
                </Header>
            </div>
            <div className="main-content">
                <SpaceBetween size="xxl" direction="vertical">
                    <Container header={<Header variant="h2">Schedule Settings</Header>}>
                        <SpaceBetween size="m" direction="vertical">
                            <ValueWithLabel label="Cadence">
                                <Tiles
                                    data-testid="cadence-input"
                                    onChange={({detail}) => {
                                        // clear both schedule on change
                                        setWeeklySchedule({});
                                        setDailySchedule({});
                                        setCadence(detail.value);
                                    }}
                                    value={cadence}
                                    items={[
                                        {label: 'Weekly', value: 'weekly'},
                                        {label: 'Daily', value: 'daily'},
                                    ]}
                                />
                            </ValueWithLabel>
                            <ValueWithLabel label="Timezone">
                                <Autosuggest
                                    data-testid="timezone-input"
                                    onChange={({detail}) => setTimezone(detail.value)}
                                    value={timezone}
                                    options={AUTO_SUGGEST_TIMEZONES}
                                    enteredTextLabel={(value) => `Use: "${value}"`}
                                    placeholder="Enter value"
                                    empty="No matches found"
                                    disableBrowserAutocorrect
                                />
                            </ValueWithLabel>
                            <ValueWithLabel label="Reset">
                                <Toggle
                                    data-testid="reset-toggle"
                                    onChange={({detail}) => setReset(detail.checked)}
                                    checked={reset}
                                />
                            </ValueWithLabel>
                        </SpaceBetween>
                    </Container>
                    {configureSchedule}
                    <Box float="right" margin={{bottom: 'l'}}>
                        <SpaceBetween direction="horizontal" size="xs">
                            <RouterButton data-testid="view-schedule-button" variant="normal" href={'/schedule'}>
                                Cancel
                            </RouterButton>
                            <Button
                                disabled={!isValidRequest}
                                data-testid="submit-button"
                                variant="primary"
                                onClick={async () => {
                                    await executePutSchedule(
                                        getPutScheduleRequest(
                                            request.businessId,
                                            request.country,
                                            request.flow,
                                            reset,
                                            timezone,
                                            cadence,
                                            weeklySchedule,
                                            dailySchedule
                                        )
                                    );
                                    router.push('/schedule', {
                                        notification: {
                                            type: 'success',
                                            header: 'Schedule is successfully updated.',
                                        },
                                    });
                                }}
                            >
                                Submit
                            </Button>
                        </SpaceBetween>
                    </Box>
                </SpaceBetween>
            </div>
        </React.Fragment>
    );
}

function checkValidRequest(
    reset: boolean,
    timezone: string,
    cadence: string,
    weeklySchedule: Orchestrator.Types.WeeklySchedule,
    dailySchedule: Orchestrator.Types.DailySchedule
) {
    if (timezone === '') {
        return false;
    }

    if (cadence === 'daily') {
        if (!dailySchedule.events || dailySchedule.events.length === 0) {
            return false;
        }
    }

    if (cadence === 'weekly') {
        const totalEventCount =
            (weeklySchedule.monday?.events?.length || 0) +
            (weeklySchedule.tuesday?.events?.length || 0) +
            (weeklySchedule.wednesday?.events?.length || 0) +
            (weeklySchedule.thursday?.events?.length || 0) +
            (weeklySchedule.friday?.events?.length || 0) +
            (weeklySchedule.saturday?.events?.length || 0) +
            (weeklySchedule.sunday?.events?.length || 0);

        if (totalEventCount === 0) {
            return false;
        }
    }

    return true;
}

function getPutScheduleRequest(
    businessId: string,
    country: string,
    flow: string,
    reset: boolean,
    timezone: string,
    cadence: string,
    weekly: Orchestrator.Types.WeeklySchedule,
    daily: Orchestrator.Types.DailySchedule
): Orchestrator.Types.PutScheduleRequest {
    const schedule = cadence === 'weekly' ? {timezone, weekly} : {timezone, daily};

    return {
        businessId,
        country,
        flow,
        reset,
        schedule,
    };
}

function compare(a: string, b: string) {
    return a < b ? -1 : a > b ? 1 : 0;
}
