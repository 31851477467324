import React from 'react';
import {Header} from '@amzn/awsui-components-react';

export default function Home() {
    return (
        <React.Fragment>
            <div className="main-header">
                <Header variant="h2">Welcome to the Excelsior Portal!</Header>
                <p>Excelsior is an S&amp;OP platform to view and edit forecast data as well as upload input signals.</p>
            </div>
        </React.Fragment>
    );
}
