import React from 'react';

export function translateErrorToReactNode(error: any) {
    if (typeof error === 'string') {
        return <React.Fragment>{error}</React.Fragment>;
    }

    if (error instanceof Error) {
        // TODO: add click to hide/show stack
        return <React.Fragment>{error.message}</React.Fragment>;
    }
    console.log(error);
    // TODO: add error display logic
    return <React.Fragment>Received unknown error</React.Fragment>;
}

export function wrapOnErrorListener<T>(promise: Promise<T>, onErrorListener: (e: any) => void): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        promise.then(resolve).catch((e) => {
            onErrorListener(e);
            reject(e);
        });
    });
}

export function openInNewTab(url: string) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
    if (newWindow) newWindow.opener = null;
}
