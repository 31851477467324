import React, {useState} from 'react';
import {Notification} from '../navigation/page-layout';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {F3ExcelsiorForecastStoreLambda} from '@amzn/f3-excelsior-forecast-store-lambda';
import {translateErrorToReactNode} from '../common';
import {useDownloadOriginalUploadArtifact, useGetHistory, useGetForecastAssetUploadStatus} from '../hooks/use-forecast-store-api';
import {Box, Button, Header, Modal, Table} from '@amzn/awsui-components-react';
import Timestamp from '../common/timestamp';
import '../../assets/styles/orchestrator-styles.scss';

export default function ViewForecastHistory(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();
    const [visible, setVisible] = useState(false);

    const forecastStoreViewClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreView);

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const {status: getHistoryStatus, value: getHistoryResponse} = useGetHistory(
        forecastStoreViewClientConfiguration,
        {
            businessId: auth.authInformation!.current!.businessId,
            country: auth.authInformation!.current!.country,
            flow: auth.authInformation!.current!.flow,
        },
        true,
        createErrorListener('GetHistory failed'),
        [auth]
    );

    const {value: getForecastAssetUploadStatusResponse, execute: executeGetForecastAssetUploadStatus} =
        useGetForecastAssetUploadStatus(
            forecastStoreViewClientConfiguration,
            createErrorListener('GetForecastAssetStatus failed'),
            [auth]
        );

    const {execute: executeDownloadOriginalUploadArtifact} = useDownloadOriginalUploadArtifact(
        forecastStoreViewClientConfiguration,
        createErrorListener('GetOriginalUploadDownloadUrl failed'),
        [auth]
    );

    const isLoading = getHistoryStatus === 'idle' || getHistoryStatus === 'pending';
    const audits = getHistoryResponse?.audits || [];

    const columnDefinitions = [
        {
            id: 'startTime',
            header: 'Start Time (Local Time Zone)',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => <Timestamp datetime={item.startTime} />,
            minWidth: '150px',
        },
        {
            id: 'eventName',
            header: 'Event Name',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => item.eventName,
            minWidth: '150px',
        },
        {
            id: 'endTime',
            header: 'End Time (Local Time Zone)',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => {
                if (item.endTime) {
                    return <Timestamp datetime={item.endTime} />;
                } else {
                    return '';
                }
            },
            minWidth: '150px',
        },
        {
            id: 'initiator',
            header: 'Initiator',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => item.initiator,
            minWidth: '100px',
        },
        {
            id: 'status',
            header: 'Status',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => item.status,
            minWidth: '100px',
        },
        {
            id: 'action',
            header: 'Action',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => {
                if (item.eventName === 'BULK_OVERRIDE' || item.eventName === 'MARKETING_EVENT') {
                    return (
                        <Button
                            data-testid="download-upload-artifact"
                            iconName="download"
                            variant="icon"
                            onClick={async () => {
                                await executeDownloadOriginalUploadArtifact({
                                    forecastId: getHistoryResponse?.forecastId || '',
                                    uploadId: item.attachment?.uploadId || '',
                                });
                            }}
                        />
                    );
                }
                return;
            },
            minWidth: '100px',
        },
        {
            id: 'info',
            header: 'Info',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => {
                if ((item.eventName === 'BULK_OVERRIDE' || item.eventName === 'MARKETING_EVENT') && item.status === 'failed') {
                    return (
                        <Button
                            data-testid="open-upload-error-modal"
                            iconName="status-info"
                            variant="icon"
                            onClick={async () => {
                                await executeGetForecastAssetUploadStatus({
                                    uploadId: item.attachment?.uploadId || '',
                                });
                                setVisible(true);
                            }}
                        />
                    );
                }
                return;
            },
            minWidth: '100px',
        },
    ];

    return (
        <React.Fragment>
            <div className="main-header">
                <Header variant="h2">Forecast History</Header>
            </div>
            <div className="main-content">
                <Table
                    data-testid="history"
                    columnDefinitions={columnDefinitions}
                    items={audits}
                    loading={isLoading}
                    loadingText="Loading history"
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No forecast history</b>
                        </Box>
                    }
                />
                <Modal
                    data-testid="upload-error-modal"
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    closeAriaLabel="Close modal"
                    size="medium"
                    header="File Errors"
                >
                    {extractUploadErrorMessage(getForecastAssetUploadStatusResponse)}
                </Modal>
            </div>
        </React.Fragment>
    );
}

function extractUploadErrorMessage(response?: F3ExcelsiorForecastStoreLambda.Types.GetForecastAssetUploadStatusResponse) {
    let errorMessage = '';
    if (response && response.fileValidationErrors) {
        for (let i = 1; i <= response.fileValidationErrors.length; i++) {
            errorMessage += '\n\t\t' + i + ')\t' + response.fileValidationErrors[i - 1];
        }
    } else if (response && response.serviceError) {
        errorMessage = response.serviceError;
    }
    return errorMessage;
}
