import React from 'react';
import {Table} from '@amzn/awsui-components-react';
import {DashboardEvents} from '@amzn/f3-excelsior-orchestrator-api/clients/orchestrator';
import {getDashEventType} from './event-popover';
import stats from 'stats-lite';
import moment from 'moment';

export default function EventStatisticsTable(props: {events: DashboardEvents}) {
    const generateTableContent = (events: DashboardEvents) => {
        const tableContent: DashboardEvents[] = [];
        const eventsByEventType = new Map<string, DashboardEvents>();

        for (const event of events) {
            if ((event.status !== 'succeed' && event.status !== 'error') || !event.startTime || !event.endTime) {
                continue;
            }
            const eventType = getDashEventType(event);
            if (!eventsByEventType.has(eventType)) {
                eventsByEventType.set(eventType, []);
            }
            eventsByEventType.get(eventType)!.push(event);
        }

        for (const type of Array.from(eventsByEventType.keys())) {
            tableContent.push(eventsByEventType.get(type)!);
        }

        return tableContent;
    };

    const generateEventTimes = (events: DashboardEvents) => {
        return events ? events.map((event) => event.endTime!.getTime() - event.startTime!.getTime()) : [];
    };

    const tableContent = generateTableContent(props.events);

    const columnDefinitions = [
        {
            id: 'eventType',
            header: 'Type',
            cell: (item: DashboardEvents) => getDashEventType(item[0]),
            minWidth: '50px',
        },
        {
            id: 'total',
            header: 'Total',
            cell: (item: DashboardEvents) => item.length,
            minWidth: '50px',
        },
        {
            id: 'succeed',
            header: 'Succeed',
            cell: (item: DashboardEvents) => item.filter((event) => event.status == 'succeed').length,
            minWidth: '50px',
        },
        {
            id: 'error',
            header: 'Error',
            cell: (item: DashboardEvents) => item.filter((event) => event.status == 'error').length,
            minWidth: '50px',
        },
        {
            id: 'slaBreached',
            header: 'SLA Breached',
            cell: (item: DashboardEvents) => item.filter((event) => event.isSlaMet == false).length,
            minWidth: '50px',
        },
        {
            id: 'average',
            header: 'Average',
            cell: (item: DashboardEvents) =>
                moment.duration(stats.mean(generateEventTimes(item)), 'milliseconds').minutes() + ' minutes',
            minWidth: '50px',
        },
        {
            id: 'p50',
            header: 'P50',
            cell: (item: DashboardEvents) =>
                moment.duration(Math.floor(stats.percentile(generateEventTimes(item), 0.5)), 'milliseconds').minutes() + ' minutes',
            minWidth: '50px',
        },
        {
            id: 'p90',
            header: 'P90',
            cell: (item: DashboardEvents) =>
                moment.duration(Math.floor(stats.percentile(generateEventTimes(item), 0.9)), 'milliseconds').minutes() + ' minutes',
            minWidth: '50px',
        },
    ];

    return (
        <>
            <Table
                data-testid="view-statistics-table"
                trackBy={(row: DashboardEvents) => getDashEventType(row[0])}
                loadingText="Loading resources"
                columnDefinitions={columnDefinitions}
                items={tableContent.sort((a, b) => getDashEventType(a[0]).localeCompare(getDashEventType(b[0])))}
            ></Table>
        </>
    );
}
