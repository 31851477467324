import {Box, Button, FormField, Input, Modal, SpaceBetween} from '@amzn/awsui-components-react';
import React, {useState} from 'react';

interface ConfirmationModalProps {
    visible: boolean;
    promptHeader: string;
    prompt: string;
    confirmPhrase: string;
    additionalInfo?: string;
    onConfirm: () => void;
    onCancel: () => void;
    showCancel?: boolean;
    showInput?: boolean;
}

export function ConfirmationModal(props: ConfirmationModalProps) {
    const defaultProps = {
        showCancel: props.showCancel ?? true,
        showInput: props.showInput ?? true,
    };
    props = {...props, ...defaultProps};
    const [message, setMessage] = useState<string>('');

    function onCancel() {
        setMessage('');
        props.onCancel();
    }

    function onConfirm() {
        setMessage('');
        props.onConfirm();
    }

    return (
        <Modal
            onDismiss={onCancel}
            visible={props.visible}
            size="medium"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        {props.showCancel && (
                            <Button data-testid="cancel-button" variant="link" onClick={onCancel}>
                                Cancel
                            </Button>
                        )}
                        <Button
                            data-testid="ok-button"
                            variant="primary"
                            onClick={onConfirm}
                            disabled={message.localeCompare(props.confirmPhrase) !== 0}
                        >
                            Ok
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={props.promptHeader}
        >
            {props.showInput && (
                <FormField
                    label={props.prompt}
                    description={
                        <span>
                            If yes, type the word <b>{props.confirmPhrase}</b>
                        </span>
                    }
                    constraintText={props.additionalInfo}
                >
                    <Input
                        data-testid="confirm-input"
                        onChange={({detail}) => setMessage(detail.value)}
                        value={message}
                        placeholder={props.confirmPhrase}
                    />
                </FormField>
            )}
            {!props.showInput && <p>{props.prompt}</p>}
        </Modal>
    );
}
