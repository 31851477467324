import React, {useState} from 'react';
import {Box, Button, Modal, SpaceBetween} from '@amzn/awsui-components-react';
import {Notification} from '../navigation/page-layout';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {useRescheduleEvents} from '../hooks/use-orchestrator-api';
import {translateErrorToReactNode} from '../common';
import {ValueWithLabel} from '../common/value-with-label';
import Timestamp from '../common/timestamp';
import {DateTimePicker} from '../common/date-time-picker';
import {Orchestrator} from '@amzn/f3-excelsior-orchestrator-api';

interface RescheduleEventModalProps {
    visible: boolean;
    event: Orchestrator.Types.ConcreteEvent;

    pushNotification: (notification: Notification) => void;
    onConfirm: () => void;
    onCancel: () => void;
}

export function RescheduleEventModal(props: RescheduleEventModalProps) {
    const auth = useAuth();
    const [datetime, setDatetime] = useState(props.event.scheduledTime);
    const clientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.OrchestratorEdit);

    const {execute: rescheduleEvents} = useRescheduleEvents(
        clientConfiguration,
        (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header: 'RescheduleEvents failed',
            });
        },
        [auth]
    );

    const onConfirm = async () => {
        await rescheduleEvents({
            businessId: auth.authInformation!.current!.businessId,
            country: auth.authInformation!.current!.country,
            flow: auth.authInformation!.current!.flow,
            rescheduleEvents: [
                {
                    eventId: props.event.eventId,
                    scheduledTime: datetime,
                },
            ],
        });
        props.onConfirm();
    };

    return (
        <React.Fragment>
            <Modal
                onDismiss={props.onCancel}
                visible={props.visible}
                size="medium"
                header={'Reschedule Event'}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button data-testid="cancel-button" variant="normal" onClick={props.onCancel}>
                                Cancel
                            </Button>
                            <Button data-testid="ok-button" variant="primary" onClick={onConfirm}>
                                Ok
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <ValueWithLabel label="Original Schedule Time">
                    <Timestamp datetime={props.event.originalScheduledTime} hour12={false} />
                </ValueWithLabel>
                <ValueWithLabel label="Current Schedule Time">
                    <Timestamp datetime={props.event.scheduledTime} hour12={false} />
                </ValueWithLabel>
                <ValueWithLabel label="New Schedule Time">
                    <DateTimePicker prefillDate={props.event.scheduledTime} onChange={setDatetime} />
                </ValueWithLabel>
            </Modal>
        </React.Fragment>
    );
}
